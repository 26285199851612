import React, { useState, useRef, useEffect } from "react";
import Header from "../Header";
import GreenCard from "./GreenCard";
import Portfolio from "./Portfolio";
import TeamMembers from "./TeamMembers";
import Footer from "../Footer";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.innerWidth <= 640 ? setIsMobile(true) : setIsMobile(false);
  }, []);
  return (
    <main className="">
      <div className=" lg:w-10/12 m-4 lg:m-auto">
        {/* Header Section */}
        {/* <Header /> */}

        {/* Main Section */}
        <section className="bg-white flex items-center justify-center h-[500px] pt-32 lg:pt-12 px-0  lg:px-4">
          <div className="container mx-auto">
            <div className="max-w-2xl text-left">
              <p className="font-plex text-[10px] sm:text-xs lg:text-[0.70rem] uppercase font-bold text-gray-500 mb-2  pb-4 whitespace-nowrap">
                Empowering Growth, Redefining Capital
              </p>

              <h1 className="font-inter text-3xl sm:text-5xl font-medium text-gray-900 max-w-xl leading-tight mb-4">
                The modern capital platform for growth on your terms
              </h1>
              <p className="text-base sm:text-lg text-gray-700 mb-6">
                The pioneering and leading sector-agnostic VC firm, passionate
                in bringing success to tech startups and sustainable impact to
                the digital ecosystem in Southeast Asia.
              </p>
              <div className="flex space-x-4">
                <button className="bg-[#0e382b] text-white px-5 py-4 rounded-[0.25rem] sm:px-5 sm:py-4 hover:bg-[#114737] transition">
                  Our Portfolio
                </button>
                <button className="bg-transparent border border-gray-300 text-gray-700 px-6 py-3 rounded-md hover:bg-gray-100 transition">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Image Section */}

        <div className="hidden md:flex flex-col items-center justify-center h-[600px] bg-white">
          <div className="flex justify-between w-[97%] -ml-0.5 mx-auto px-6 space-x-4">
            <img
              src={`${process.env.PUBLIC_URL}/img/first_img.png`}
              alt="People in a meeting with a whiteboard"
              className="w-1/4 custom-shape rounded-br-[6rem]"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/second_img.png`}
              alt="People in a meeting room"
              className="w-1/4 custom-shape rounded-tl-[13rem]"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/third_img.png`}
              alt="Person working on a laptop"
              className="w-1/4 custom-shape rounded-bl-[6rem] rounded-br-2xl"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/second_img.png`}
              alt="People in a meeting room"
              className="w-1/4 custom-shape rounded-tl-[13rem]"
            />
          </div>
        </div>

        {/* Vision Section - Shown on mobile */}
        <div className="block md:hidden font-jakarta font-medium leading-10 text-left pt-10 pb-10 w-full max-w-4xl mt-8">
          {/* Added margin-top */}
          <div className="leading-loose lg-px-4 ">
            <p className="text-black text-2xl sm:text-3xl scroll-mt-10 font-medium mb-4">
              We're eliminating the friction and bias of traditional financing,
            </p>
            <p className="text-gray-500 text-2xl sm:text-3xl font-medium mb-6">
              connecting business builders to quick, easy capital, and helping
              small to mid-size businesses build something bigger.
            </p>
          </div>
        </div>

        {/* Vision Section - Shown below Image Section on larger screens */}
        <div className="hidden md:block font-jakarta font-medium leading-10 text-left pb-10 w-full max-w-4xl mt-8">
          {" "}
          {/* Added margin-top */}
          <div className="leading-loose">
            <p className="text-black text-3xl pl-4 -mt-14 font-medium">
              We're eliminating the friction and bias of traditional financing,
            </p>
            <p className="text-gray-500 text-3xl pl-4 font-medium mb-2">
              connecting business builders to quick, easy capital, and helping
              small to mid-size businesses build something bigger.
            </p>
          </div>
        </div>

        <div className="justify-center">
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-[5rem] sm:p-20 mb-20">
            <div className="bg-green-100 flex bg-opacity-50 text-gray-600 mb-2 md:mb-0">
              <img
             
                className="w-5 h-5 mr-2"
                src={`${process.env.PUBLIC_URL}/img/build_img.png`}
                 alt="No Image"
              />
              Venture studio model
            </div>

            <div className="bg-green-100 flex bg-opacity-50 text-gray-600 mb-4 md:mb-0">
              <img
                className="w-5 h-5 mr-2"
                src={`${process.env.PUBLIC_URL}/img/graph.png`}
              alt="No Image"
                />
              Venture studio model
            </div>

            <div className="bg-green-100 flex bg-opacity-50 text-gray-600 mb-4 md:mb-0">
              <img
                className="w-5 h-5 mr-2"
                 alt="No Image"
                src={`${process.env.PUBLIC_URL}/img/rocket.png`}
              />{" "}
              Venture studio model
            </div>

            <div className="bg-green-100 flex bg-opacity-50 text-gray-600 md:mb-0">
              <img
                className="w-5 h-5 mr-2"
                 alt="No Image"
                src={`${process.env.PUBLIC_URL}/img/build_img.png`}
              />{" "}
              Venture studio model
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-11 w-[100%] pb-16 lg:pl-4 ">
            <div className="md:w-1/2">
              <div className="text-gray-700 text-[0.70rem] font-semibold font-plex mb-6">
                COMPANY VISION
              </div>
              <h1 className="text-4xl font-jakarta mb-6">
                Solve tomorrow’s vast challenges with technology
              </h1>
              <ul className="space-y-10 py-11 pl-6 text-lg">
                <li>Empowering Innovation</li>
                <li>Making technology accessible</li>
                <li>Pioneering a sustainable future</li>
                <li>Empowering Innovation</li>
              </ul>
            </div>
            <div className="mt-6 md:mt-0 md:w-[45%]">
              <img
                src={`${process.env.PUBLIC_URL}/img/fourth.png`}
                alt="Person working on a laptop in a modern office space"
                className="rounded-[0.2rem] shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </div>
      {/* GreenCard Section */}
      <GreenCard />

      {/* Portfolio Section */}
      <Portfolio isMobile={isMobile} />

      {/* Team Members Section */}
      <TeamMembers />

      {/* Footer Section */}
      {/* <Footer /> */}
    </main>
  );
};

export default Home;
