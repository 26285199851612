import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";

const PortfolioItem = ({ logo, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow w-1/4 transition-transform transform hover:scale-105 hover:z-10">
      <img src={logo} alt={`${title} logo`} className="mx-auto mb-4" />
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const NorthstarCapitalSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleDrawer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const accordionItems = [
    {
      title: "Collaborative Discovery",
      content: "Details about Collaborative Discovery",
    },
    {
      title: "Tailored Strategic Planning",
      content: "Details about Tailored Strategic Planning",
    },
    {
      title: "Financial Empowerment",
      content: "Details about Financial Empowerment",
    },
    {
      title: "Celebrate Success Together",
      content: "Details about Celebrate Success Together",
    },
  ];

  const items1 = [
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba0ccb1057dc9919e3f76a_QuatumWorks.svg",
      title: "QuantumWorks",
      description:
        "Revolutionizing industries through advanced tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba0bb7512cb470aeecd934_NovaFin%20Solution.svg",
      title: "NovaFin Solution",
      description:
        "Revolutionizing industries through advanced tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba0b37c1c574d6637ad17b_FinTech%20Forge.svg",
      title: "FinTech Forge",
      description:
        "Revolutionizing finance through technology-driven solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba08b9ff42704d5e959126_InnovateTech%20Solutions.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
  ];

  const items2 = [
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c21fab7c9bb9e250f8ed00_StroomWork.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c21f852d49ab2835972a49_AeroTech%20Innovate.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c21f61620cb0e4abc5288e_DeepSky%20Dynamics%20Logo.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c21f1b47d3f9b393598169_EnoInnovators%20Company.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
  ];

  const items3 = [
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c2204af503634c22840eff_Ammanah%20Innvoation.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c2222561828f24e5915a9d_BioGen%20Innovations.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c21ffc291d8ef94c4ca8cf_NeuroHealth%20Solutions.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65c21fab7c9bb9e250f8ed00_StroomWork.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.innerWidth <= 640 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <>
      {/* Section with media queries for responsiveness */}

      <div className="bg-[#0e382b] flex flex-col lg:pl-4 lg:pr-4 items-center justify-center pt-10 min-h-screen">
        <div className="w-11/12 mx-auto  flex flex-col lg:flex-row items-center lg:items-center justify-between h-full">
          <div className="lg:w-1/2 flex flex-col items-start justify-center max-w-lg mr-24 pl-8 text-left">
            <p className="uppercase text-xs font-plex tracking-widest pl-6 text-white mb-4">
              Empowering Growth - Redefining Capital
            </p>
            <h1 className="text-white text-3xl font-jakarta lg:text-4xl pl-6 mb-6 leading-tight">
              Exploring opportunities to join NorthstarCapital's thriving
              venture
            </h1>
            <p className="text-white text-lg pl-6 mb-8">
              Embark on a Journey of Innovation and Success: Unveiling
              NorthstarCapital's Portfolio Pioneers. Explore the Ventures
              Shaping Tomorrow's Industries.
            </p>
            <div className="flex justify-start pl-6">
              <button className="bg-lime-400 hover:bg-lime-500 text-black py-5 px-7   w-auto rounded-lg transition duration-300">
                Explore Portfolio
              </button>
            </div>
          </div>

          {/* Right side Image Section */}
          <div className="lg:w-[60%] mt-8 lg:mt-0 h-[70%] flex items-stretch justify-center  lg:mx-0">
            <img
              src={`${process.env.PUBLIC_URL}/img/P_img1.png`}
              alt="NorthstarCapital"
              className="rounded-[0.5rem] lg:rounded-tl-[7rem] object-cover w-full h-[50vh] lg:h-[100vh]"
            />
          </div>
        </div>
      </div>

      <p className="text-center font-inter text-[1.2rem] pt-20 pb-20">
        Used by the world's most average companies
      </p>

      {/* Logo Carousel Section */}
      <div>
        <Marquee gradient={false} speed={50}>
          <div className="flex  space-x-20 ">
            {/* First div */}
            <div className="flex space-x-20">
              <img
                src={`${process.env.PUBLIC_URL}/img/Omnivio.png`}
                alt="Omnivio Logo"
                className="w-40 h-16 pl-5"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Nanonets`}
                alt="Nanonets Logo"
                className="w-40 h-16"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Urturms`}
                alt="Urturm Logo"
                className="w-40 h-16"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Detect`}
                alt="Detect Logo"
                className="bg-black m-4 w-40 h-16"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Cre.png`}
                alt="Cre Logo"
                className="w-40 h-16 "
              />
            </div>

            {/* Second div (inline with first div) */}
            <div className="flex space-x-20" aria-hidden="true">
              <img
                src={`${process.env.PUBLIC_URL}/img/Omnivio.png`}
                alt="Omnivio Logo"
                className="w-40 h-16 "
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Nanonets`}
                alt="Nanonets Logo"
                className="w-40 h-16"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Urturms`}
                alt="Urturm Logo"
                className="w-40 h-16"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Detect`}
                alt="Detect Logo"
                className="bg-black m-4 w-40 h-16"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/Cre.png`}
                alt="Cre Logo"
                className="w-40 h-16 "
              />
            </div>
          </div>
        </Marquee>
      </div>

      <div className="pt-20 w-11/12 lg:pl-9 mx-auto">
       
        <div className="flex flex-col md:flex-row items-start justify-center md:mx-auto md:px-10 md:py-8">
          {/* Image Section, which will appear first on all screen sizes */}
          <div className="md:w-[70%] w-full mb-6 md:mb-0">
            <img
              src={`${process.env.PUBLIC_URL}/img/office.webp`}
              alt="Office"
              className="w-full sm:w-[90%] lg:w-[100%] lg:h-[85vh] rounded-lg"
            />
          </div>

          {/* Accordion Section */}
          <div className="md:w-10/12 md:pl-28 md:px-6 text-left">
            <h2 className="text-2xl md:text-3xl font-jakarta font-semibold mb-4">
              Navigating Success Through Four Key Steps with NorthstarCapital
            </h2>
            <p className="text-gray-500 mb-8 text-xl">
              We've created a frictionless venture studio process that is fully
              aligned with the success of your business.
            </p>

            {accordionItems.map((item, index) => (
              <div
                key={index}
                className="mb-4 font-jakarta text-2xl pt-4 pb-12"
              >
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleDrawer(index)}
                >
                  <h3 className="font-semibold">{item.title}</h3>
                  <button className="text-lg font-bold">
                    {openIndex === index ? "-" : "+"}
                  </button>
                </div>

                <div
                  className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                    openIndex === index ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <p className="mt-2 text-[1rem] text-gray-500">
                    {item.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-white w-11/12 mx-auto  py-16 ">
        <div className="max-w-7xl mx-auto px-4 lg:px-6">
          <h2 className="text-4xl font-jakarta font-semibold max-w-lg pb-14 text-gray-900 mb-12 text-left">
            A new model for growing high tech companies
          </h2>
          <div className="grid grid-cols-2 gap-7 sm:grid-cols-2 lg:grid-cols-4">
            <div className="lg:p-6 text-left lg:border-l-2 border-gray-300">
              <h3 className="text-xl text-gray-900">B2B tech platform</h3>
              <p className="mt-4 text-sm text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="lg:p-6 text-left lg:border-l-2 border-gray-300">
              <h3 className="text-xl text-gray-900">Industry expertise</h3>
              <p className=" text-sm text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="lg:p-6 text-left lg:border-l-2 border-gray-300">
              <h3 className="text-xl text-gray-900">Scalable business case</h3>
              <p className=" text-sm text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="lg:p-6 text-left lg:border-l-2 border-gray-300">
              <h3 className="text-xl text-gray-800">Attractive data model</h3>
              <p className=" text-sm text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-10/12 m-auto px-4 md:pl-6 md:pt-16 ">
        <div className="text-gray-500 text-xs font-semibold mb-2  font-plex">
          OUR PORTFOLIO
        </div>
        <h1 className=" text-4xl font-jakarta font-medium mb-8">
          A global force for innovation
        </h1>
        <div className="w-11/12 flex flex-col md:flex-row justify-between items-center mb-8 pt-14 pb-14">
          <h2 className="text-4xl font-semibold font-jakarta self-start md:self-center">
            Enterprise
          </h2>
          <button className="hidden md:block bg-green-900 text-white py-2 px-4 rounded mt-4 md:mt-0">
            More Portfolio
          </button>
        </div>

        {!isMobile ? (
          <>
            <div className="flex flex-col md:flex-row  md:space-x-8">
              {items1.map((item, index) => (
                <PortfolioItem
                  key={index}
                  logo={item.logo}
                  title={item.title}
                  description={item.description}
                  className="h-10"
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col md:flex-row md:space-x-8">
              {items1.map((item, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/3 bg-white  rounded-lg pt-12  mt-16 flex flex-col justify-between h-64"
                >
                  <div className="flex justify-center items-center pb-20 h-24">
                    <img
                      src={item.logo}
                      alt={item.title}
                      className="h-20 w-20 m-10 py-4"
                    />
                  </div>
                  <div className="w-full">
                    <h3 className="font-semibold text-lg truncate text-left">
                      {item.title}
                    </h3>
                    <p className="text-gray-600 truncate  text-wrap text-left">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="w-11/12 flex flex-col md:flex-row justify-between items-center mb-8 pt-14 pb-14">
          <h2 className="text-4xl font-semibold font-jakarta self-start md:self-center">
            Frontier Tech
          </h2>
        </div>

        {!isMobile ? (
          <>
            <div className="flex flex-col md:flex-row md:space-x-8">
              {items2.map((item, index) => (
                <PortfolioItem
                  key={index}
                  logo={item.logo}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col md:flex-row md:space-x-8">
              {items2.map((item, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/3 bg-white  rounded-lg pt-12  mt-16 flex flex-col justify-between h-64"
                >
                  <div className="flex justify-center items-center pb-20 h-24">
                    <img
                      src={item.logo}
                      alt={item.title}
                      className="h-20 w-20 m-10 py-4"
                    />
                  </div>
                  <div className="w-full">
                    <h3 className="font-semibold text-lg truncate text-left">
                      {item.title}
                    </h3>
                    <p className="text-gray-600 truncate text-wrap text-left">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="w-11/12 flex flex-col md:flex-row justify-between items-center mb-8 pt-14 pb-14">
          <h2 className="text-4xl font-semibold font-jakarta self-start md:self-center">
            Life Science
          </h2>
        </div>

        {!isMobile ? (
          <>
            <div className="flex flex-col md:flex-row md:space-x-8">
              {items3.map((item, index) => (
                <PortfolioItem
                  key={index}
                  logo={item.logo}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col md:flex-row md:space-x-8">
              {items3.map((item, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/3 bg-white rounded-lg pt-12  mt-16  flex flex-col justify-between h-64"
                >
                  <div className="flex justify-center items-center pb-20 h-24">
                    <img
                      src={item.logo}
                      alt={item.title}
                      className="h-20 w-20 m-10 py-4"
                    />
                  </div>
                  <div className="w-full">
                    <h3 className="font-semibold text-lg truncate text-left">
                      {item.title}
                    </h3>
                    <p className="text-gray-600 truncate text-left">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="text-center pt-20 pb-10">
          <h3 className="font-plex opacity-80  text-[0.60rem] pb-5">
            WHAT CEO SAY'S ABOUT US
          </h3>
          <h1 className="font-jakarta font-[450] max-w-[34rem] m-auto text-2xl">
            Join the CEO who've already used NorthstarCapital to fund their
            business
          </h1>
        </div>
      </div>

      {!isMobile ? (
        <div className="w-11/12 m-auto ">
          <Marquee gradient={false} speed={50}>
            <div className="flex gap-10 p-6 ">
              {/* Card 1 */}
              <div className="bg-gray-100 text-gray-800 rounded-lg p-6 max-w-md shadow-md">
                <p className="text-lg mb-6">
                  NorthstarCapital transformed our vision into reality. Their
                  support goes beyond funding; it's a partnership for lasting
                  success.
                </p>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-semibold">Robbie Fleming</p>
                    <p className="text-sm text-gray-600">
                      CEO Of Company executive officer
                    </p>
                  </div>
                  <img
                    src="/path-to-logoipsum.png"
                    alt="Logoipsum"
                    className="h-6"
                  />
                </div>
              </div>

              {/* Card 2 */}
              <div className="bg-lime-200 text-black rounded-lg p-6 flex flex-col w-full max-w-md">
                <img
                  src="https://cdn.prod.website-files.com/65b76f3913e54c073d06bfe9/65c3240481e3f2d95a7854a3_CEO%20Image.webp"
                  alt="Robbie Fleming"
                  className="rounded-md mb-4"
                />
                <p className="mb-6 pt-11 text-lg">
                  NorthstarCapital transformed our vision into reality. Their
                  support goes beyond funding; it's a partnership for lasting
                  success.
                </p>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-semibold pt-6">Robbie Fleming</p>
                    <p className="text-sm text-gray-600">
                      CEO Of Company executive officer
                    </p>
                  </div>
                  <img
                    src="https://cdn.prod.website-files.com/65b76f3913e54c073d06bfe9/65c07c17db6f2c3d9f533095_Logo%20Company02.svg"
                    alt="Logoipsum"
                    className="w-16 h-20 pt-5"
                  />
                </div>
              </div>

              {/* Card 3 */}
              <div className="bg-[#1c1c1c] text-white rounded-lg p-6 flex flex-col w-full max-w-md">
                <p className="mb-6 text-lg">
                  Innovation thrives with NorthstarCapital. Strategic guidance
                  and a collaborative approach make them the perfect ally for
                  entrepreneurial journeys.
                </p>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-semibold pt-9">Robbie Fleming</p>
                    <p className="text-sm text-gray-400">
                      CEO Of Company executive officer
                    </p>
                  </div>
                  <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center">
                    {/* Icon can be added here */}
                  </div>
                </div>
              </div>

              {/* Card 4 */}
              <div className="bg-emerald-900 text-lime-100 rounded-lg p-6 max-w-md">
                <p className="text-lg font-semibold mb-4">
                  NorthstarCapital isn't just an investor; they are architects
                  of success. Their strategic insights and unwavering support
                  turned our ambitious vision into a thriving reality.
                </p>
                <p className="mb-6">
                  The collaborative partnership is more than financial—it's a
                  journey where innovation meets impact. Proud to have
                  NorthstarCapital as our guiding force in the entrepreneurial
                  landscape.
                </p>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-semibold">Robbie Fleming</p>
                    <p className="text-sm text-lime-300">
                      CEO Of Company executive officer
                    </p>
                  </div>
                  <div className="w-10 h-10 bg-lime-300 rounded-full flex items-center justify-center">
                    {/* Icon can be added here */}
                  </div>
                </div>
              </div>
            </div>
          </Marquee>
        </div>
      ) : (
        <div className="flex flex-col gap-4 p-4 sm:flex-row sm:gap-10 sm:p-6">
          {/* Card 1 */}
          <div className="bg-gray-100 text-gray-800 rounded-lg p-4 w-full shadow-md max-w-full sm:max-w-md">
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              NorthstarCapital transformed our vision into reality. Their
              support goes beyond funding; it's a partnership for lasting
              success.
            </p>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-semibold">Robbie Fleming</p>
                <p className="text-sm text-gray-600">
                  CEO Of Company executive officer
                </p>
              </div>
              <img
                src="/path-to-logoipsum.png"
                alt="Logoipsum"
                className="h-6"
              />
            </div>
          </div>

          {/* Card 2 */}
          <div className="bg-lime-200 text-black rounded-lg p-4 w-full flex flex-col max-w-full sm:max-w-md">
            <img
              src="https://cdn.prod.website-files.com/65b76f3913e54c073d06bfe9/65c3240481e3f2d95a7854a3_CEO%20Image.webp"
              alt="Robbie Fleming"
              className="rounded-md mb-2 sm:mb-4"
            />
            <p className="mb-4 sm:mb-6 sm:pt-11 text-base sm:text-lg">
              NorthstarCapital transformed our vision into reality. Their
              support goes beyond funding; it's a partnership for lasting
              success.
            </p>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-semibold sm:pt-6">Robbie Fleming</p>
                <p className="text-sm text-gray-600">
                  CEO Of Company executive officer
                </p>
              </div>
              <img
                src="https://cdn.prod.website-files.com/65b76f3913e54c073d06bfe9/65c07c17db6f2c3d9f533095_Logo%20Company02.svg"
                alt="Logoipsum"
                className="w-12 h-14 sm:w-16 sm:h-20"
              />
            </div>
          </div>

          {/* Card 3 */}
          <div className="bg-[#1c1c1c] text-white rounded-lg p-4 w-full flex flex-col max-w-full sm:max-w-md">
            <p className="mb-4 sm:mb-6 text-base sm:text-lg">
              Innovation thrives with NorthstarCapital. Strategic guidance and a
              collaborative approach make them the perfect ally for
              entrepreneurial journeys.
            </p>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-semibold">Robbie Fleming</p>
                <p className="text-sm text-gray-400">
                  CEO Of Company executive officer
                </p>
              </div>
              <div className="w-8 h-8 sm:w-10 sm:h-10 bg-white rounded-full flex items-center justify-center">
                {/* Icon can be added here */}
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className="bg-emerald-900 text-lime-100 rounded-lg p-4 w-full max-w-full sm:max-w-md">
            <p className="text-base sm:text-lg font-semibold mb-2 sm:mb-4">
              NorthstarCapital isn't just an investor; they are architects of
              success. Their strategic insights and unwavering support turned
              our ambitious vision into a thriving reality.
            </p>
            <p className="text-sm mb-4 sm:mb-6">
              The collaborative partnership is more than financial—it's a
              journey where innovation meets impact. Proud to have
              NorthstarCapital as our guiding force in the entrepreneurial
              landscape.
            </p>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-semibold">Robbie Fleming</p>
                <p className="text-sm text-lime-300">
                  CEO Of Company executive officer
                </p>
              </div>
              <div className="w-8 h-8 sm:w-10 sm:h-10 bg-lime-300 rounded-full flex items-center justify-center">
                {/* Icon can be added here */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NorthstarCapitalSection;
