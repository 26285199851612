import React, { useState, useRef } from "react";

const PortfolioItem = ({ logo, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow w-1/4 transition-transform transform hover:scale-105 hover:z-10">
      <img src={logo} alt={`${title} logo`} className="mx-auto mb-4" />
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const Portfolio = ({ isMobile }) => {
  const items = [
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba0ccb1057dc9919e3f76a_QuatumWorks.svg",
      title: "QuantumWorks",
      description:
        "Revolutionizing industries through advanced tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba0bb7512cb470aeecd934_NovaFin%20Solution.svg",
      title: "NovaFin Solution",
      description:
        "Revolutionizing industries through advanced tech solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba0b37c1c574d6637ad17b_FinTech%20Forge.svg",
      title: "FinTech Forge",
      description:
        "Revolutionizing finance through technology-driven solutions.",
    },
    {
      logo: "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65ba08b9ff42704d5e959126_InnovateTech%20Solutions.svg",
      title: "InnovateTech Solutions",
      description:
        "Transforming industries through cutting-edge tech solutions.",
    },
  ];

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="lg:w-10/12 m-4 lg:m-auto pt-20">
      <div className="text-gray-500 text-xs font-semibold mb-2 font-plex">
        OUR PORTFOLIO
      </div>
      <h1 className=" text-4xl font-jakarta font-medium mb-8">
        A global force for innovation
      </h1>
      <div className="w-11/12 flex flex-col md:flex-row justify-between items-center mb-8 pt-14 pb-14">
        <h2 className="text-2xl font-semibold self-start md:self-center">
          Enterprise
        </h2>
        <button className="hidden md:block bg-green-900 text-white py-2 px-4 rounded mt-4 md:mt-0">
          More Portfolio
        </button>
      </div>
      {!isMobile ? (
        <>
          <div className="flex flex-col  md:flex-row md:space-x-8">
            {items.map((item, index) => (
              <PortfolioItem
                key={index}
                logo={item.logo}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:space-x-8">
            {items.map((item, index) => (
              <div
                key={index}
                className="w-full md:w-1/3 bg-white  rounded-lg pt-12  mt-16 flex flex-col justify-between h-64"
              >
                <div className="flex justify-center items-center pb-20 h-24">
                  <img
                    src={item.logo}
                    alt={item.title}
                    className="h-20 w-20 m-10 py-4"
                  />
                </div>
                <div className="w-full">
                  <h3 className="font-semibold text-lg truncate text-left">
                    {item.title}
                  </h3>
                  <p className="text-gray-600 truncate text-left">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="w-[95%] scroll-pl-9 font-semibold m-auto text-left">
        <p className="font-plex text-xs font-semibold pt-10 pb-5 opacity-60 ">
          Our Portfolio
        </p>
        <h1 className="font-inter text-4xl font-medium mb-8">
          A global force for innovation
        </h1>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-start mt-16 space-y-8 md:space-y-0 md:space-x-8">
        {/* Dr. Reynolds Card */}
        <div className="relative bg-white rounded-lg shadow w-full md:w-[60%] h-[300px] md:h-[500px] overflow-hidden">
          <img
            src="https://assets-global.website-files.com/65b76f3913e54c073d06bfe9/65ba1dab91da1a0c627637f6_Dr%20Reynold%20Image.webp"
            alt="Dr. Reynolds"
            className="w-full h-full object-cover rounded-lg"
          />
          <div className="absolute inset-0 flex flex-col justify-end bg-black bg-opacity-40 p-4 md:p-10 text-white">
            <p className="mb-4 md:mb-10 text-xl md:text-2xl font-inter">
              Embarking on the journey with NorthstarCapital was a pivotal
              decision for QuantumForge Dynamics.
            </p>
            <h3 className="text-sm md:text-base font-semibold mb-1">
              Dr. Reynolds
            </h3>
            <p className="text-xs md:text-sm">
              CEO and Founder of QuantumForge Dynamics
            </p>
          </div>
        </div>

        {/* Shareholder Value Card */}
        <div className="bg-[#071c17] text-[#cefe85] p-4 md:p-6 rounded-lg shadow w-full md:w-[50%] h-[300px] md:h-[500px] flex flex-col justify-between">
          <div>
            <h4 className="text-xs md:text-sm font-plex mb-2 md:mb-4">
              SHAREHOLDER VALUE CREATED
            </h4>
          </div>
          <div>
            <p className="text-4xl md:text-6xl font-inter mb-1 md:mb-2">
              €150M+
            </p>
            <p className="text-3xl md:text-5xl font-inter">
              The biggest in South East Asia
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-5 md:flex-row justify-between items-start mt-16 space-y-6 md:space-y-0 md:space-x-8">
        {/* First Card */}
        <div className="flex flex-col md:flex-row gap-6">
          {/* First Card */}
          <div className="bg-[#0e382b] text-white p-6 rounded-lg shadow w-full md:w-[30%] md:min-h-[650px] h-full">
            <h4 className="text-xs uppercase mb-2 md:pb-10">
              Venture CEO Said
            </h4>
            <img
              src={`${process.env.PUBLIC_URL}/img/specs`}
              alt="CEO"
              className="w-full h-auto rounded mb-4"
            />
            <p className="text-lg mb-4">
              "We’ll continue to use NorthstarCapital no matter what stage we’re
              in, it’s unbiased capital."
            </p>
          </div>

          {/* Second Card */}
          <div className="bg-[#cdf8d4] text-white rounded-lg shadow w-full md:w-[68%] relative p-6 md:p-6 md:min-h-[570px] h-full">
            <div className="relative">
              <video
                ref={videoRef}
                className="w-full h-[400px] md:h-[600px] object-cover rounded"
                controls={false}
              >
                <source
                  src={`${process.env.PUBLIC_URL}/img/self.webm`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>

              {/* Play/Pause Button Overlay */}
              <button
                className="absolute bottom-4 right-4 md:right-6 bg-white text-green-900 px-4 py-2 rounded-md shadow"
                onClick={togglePlayPause}
              >
                {isPlaying ? (
                  <i className="fas fa-pause-circle"></i>
                ) : (
                  <i className="fas fa-play-circle"></i>
                )}
                 </button>

              {/* Text Overlay */}
              <div className="absolute inset-0 flex p-4 bg-opacity-50 bg-black md:bg-transparent">
                <p className="text-xl md:text-3xl font-bold text-[#cdf8d4] text-left lg:mt-[29rem] max-w-xl">
                  Sustainable Solutions is committed to transforming agriculture
                  with sustainable practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
