import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PitchSection = () => {
  return (
    <div className="">
      <div className="bg-gray-100 sm:py-32 ">
      <div className="text-center max-w-2xl mx-auto ">
        <h2 className="text-5xl font-jakarta mb-4 ">Pitch Your Vision, Let's Talk.</h2>
        <p className="text-gray-600 mb-6">
          Got an innovative venture? Share your pitch with NorthstarCapital and schedule a meeting.
          Submit your email below, and let's explore the potential partnership together.
        </p>
        <div className="flex flex-col px-4 items-center">
          <input
            type="email"
            placeholder="Enter your email"
            className="p-2 border border-gray-300 rounded-md w-full  max-w-xs mb-4"
          />
          <button className="bg-yellow-400 text-white px-6 py-2 w-full max-w-xs rounded-md transition duration-300 hover:bg-yellow-300">
            Submit
          </button>
        </div>

        
      </div>
      </div>

      <div className="flex flex-col items-center justify-center min-h-full my-24 w-11/12 mx-auto bg-white text-gray-800">
    <div className="w-full max-w-6xl ">
        <div className="flex flex-col lg:flex-row justify-between items-start gap-8 lg:gap-32 mb-8">
        <Link to="/" className="text-2xl">
            <span className="text-black font-bold">Northstar</span>
            <span className="text-gray-700">Capital</span>
          </Link>

            <div className="lg:flex lg:space-x-16 lg:ml-20">
                <div className="mb-4 lg:mb-0">
                    <h2 className="text-xs font-plex font-medium text-gray-700 mb-2">COMPANY</h2>
                    <ul className="space-y-6 mb-6">
                        <li>Company</li>
                        <li>Solution</li>
                        <li>Portfolio</li>
                    </ul>
                </div>
                <div className="mb-4 lg:mb-0">
                    <h2 className="text-xs font-plex font-medium text-gray-700 mb-2">RESOURCE</h2>
                    <ul className="space-y-6 mb-6">
                        <li>Blog</li>
                        <li>Article</li>
                    </ul>
                </div>
                <div className="mb-4 lg:mb-0">
                    <h2 className="text-xs font-plex font-medium text-gray-700 mb-2">TEMPLATE</h2>
                    <ul className="space-y-6 mb-6">
                        <li>Changelog</li>
                        <li>License</li>
                        <li>Styleguide</li>
                        <li>Sign In</li>
                        <li>Sign Up</li>
                    </ul>
                </div>
                <div className="flex flex-col mb-4 lg:mb-0">
                    <h2 className="text-lg font-semibold mb-2">Join our newsletter</h2>
                    <p className="text-sm mb-4">Join our newsletter to stay up to date on features and releases.</p>
                    <div className="flex space-x-2 mb-2">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="px-4 py-2 border border-gray-300 rounded-md w-full"
                        />
                        <button className="px-4 py-2 bg-green-900 text-white rounded-md">Submit</button>
                    </div>


                    <p className="text-xs text-gray-500">
                        By subscribing you agree to with our Privacy Policy and provide consent to receive updates from our company.
                    </p>
                </div>
            </div>
        </div>

        <div className="flex justify-center lg:justify-start  space-x-4 mb-8">
            <a href="#" className="text-gray-500 hover:text-gray-700"><i className="fab fa-facebook-f"></i></a>
            <a href="#" className="text-gray-500 hover:text-gray-700"><i className="fab fa-instagram"></i></a>
            <a href="#" className="text-gray-500 hover:text-gray-700"><i className="fab fa-twitter"></i></a>
            <a href="#" className="text-gray-500 hover:text-gray-700"><i className="fab fa-linkedin-in"></i></a>
            <a href="#" className="text-gray-500 hover:text-gray-700"><i className="fab fa-youtube"></i></a>
        </div>

        <div className="text-center lg:text-right text-gray-500 text-sm">
            © NorthStarCapital. All rights reserved.
        </div>
    </div>
</div>


    </div>
  );
};

export default PitchSection;
  